import { AxiosInstance } from 'axios';

import { TLeasingData } from '@/types/leasing';

export class LeasingAdminApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllLeasing(
    limit?: number,
    offset?: number,
    filterByUser?: string,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TLeasingData[];
  }> {
    let path = `admin/leasing_companies?`;
    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (offset) {
      path = path + `&offset=${offset}`;
    }
    if (filterByUser) {
      path = path + `&filterByUser=${filterByUser}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }

    const res = await this.api.get(path);
    return res.data;
  }
}
