import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TDealer, TDealerDto } from '@/types/dealer.type';
import { dealersApi } from '@/integrations/index.api';
import { mapDealerData } from '@/services/mappers/adminDealersMapper';

type TDealersSlice = {
  isLoading: boolean;
  total: number;
  dealers: TDealer[];
  dealer: TDealerDto | null;
};

const initialState: TDealersSlice = {
  isLoading: false,
  total: 0,
  dealers: [],
  dealer: null
};

export const getAllDealers = createAsyncThunk(
  'admin/dealers/getAll',
  async ({
    limit,
    page,
    param,
    filterParam
  }: {
    limit?: number;
    page?: number;
    param?: string;
    filterParam?: string;
  }) => {
    const res = await dealersApi.getAdminAllDealers(
      limit,
      page,
      param,
      filterParam
    );

    return res;
  }
);

export const getDealerByParam = createAsyncThunk(
  'admin/dealers/getDealerByParam',
  async (param: number | string) => {
    const res = await dealersApi.getAdminDealerByParam(param);

    return res;
  }
);

export const getDealerById = createAsyncThunk(
  'admin/getDealerById',
  async (dealerId: string) => {
    const res = await dealersApi.getAdminById(dealerId);
    return res.data;
  }
);

export const createDealer = createAsyncThunk(
  'admin/dealers/create',
  async (dealer: TDealerDto) => {
    return await dealersApi.createAdminDealer(dealer);
  }
);

export const updateDealer = createAsyncThunk(
  'admin/dealers/update',
  async (dealer: TDealer, { rejectWithValue }) => {
    try {
      const res = await dealersApi.updateAdminDealer(dealer);
      return res;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const adminDealersSlice = createSlice({
  name: 'admin/dealers',
  initialState: initialState,
  reducers: {
    resetDealerState(state) {
      state.dealer = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDealers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDealers.fulfilled, (state, action) => {
        state.dealers = action.payload.dealers;
        state.isLoading = false;
        state.total = action.payload.total;
      })
      .addCase(getDealerByParam.fulfilled, (state, action) => {
        state.dealers = action.payload;
      })
      .addCase(getDealerById.fulfilled, (state, action) => {
        const parsedData = mapDealerData(action.payload);
        state.isLoading = false;
        state.dealer = parsedData;
      })
      .addCase(getDealerById.pending, (state) => {
        state.isLoading = true;
        state.dealer = null;
      })
      .addCase(getDealerById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetDealerState } = adminDealersSlice.actions;
export default adminDealersSlice.reducer;
