import { AxiosInstance } from 'axios';
import { TSalePoint } from '@/types/salePoint.type';

export class SalePointsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminSalePoints(): Promise<TSalePoint[]> {
    const res = await this.api.get(`admin/sale_points/getAll`);
    return res.data;
  }
}
