export const clearLocalStorageHelper = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userSearchType');
  localStorage.removeItem('isRemember');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('emailAllur');
  localStorage.removeItem('paginationInfo');
  localStorage.removeItem('dealer');
  localStorage.removeItem('isAuthenticated');
};
