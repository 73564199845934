import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotificationsStore } from '../types/store';
import { TNotificationServerResponse } from '../types/notifications';

const initialState: TNotificationsStore = {
  notifications: [],
  lastNotificationsChecked: true,
  showNotifications: false
};

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    onToggleShowNotifications: (state) => {
      state.showNotifications = !state.showNotifications;
    },
    onChangeLastNotificationChecked: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.lastNotificationsChecked = action.payload;
    },
    addNewNotification: (
      state,
      action: PayloadAction<TNotificationServerResponse>
    ) => {
      state.lastNotificationsChecked = false;
      state.notifications = [action.payload, ...state.notifications];
    }
  },
  extraReducers: (builder) => {
    builder;
  }
});

export const {
  onToggleShowNotifications,
  onChangeLastNotificationChecked,
  addNewNotification
} = notificationsSlice.actions;
