import { TLeasingTableData, TServerLeasingsResponse } from '@/types/leasing';

export const leasingsResponseMapper = (
  serverLeasingsResponse: TServerLeasingsResponse[]
): TLeasingTableData[] => {
  return serverLeasingsResponse
    ? serverLeasingsResponse
        .map((leasing) => {
          const formattedValue: TLeasingTableData = {
            uuid: leasing?.uuid || '',
            iin: leasing?.iin || '',
            companyName: leasing?.company_name || '',
            totalAmount: leasing?.total_amount || null,
            initialPayment: leasing?.initial_payment || null,
            createdDate: leasing?.create_date || '',
            updatedDate: leasing?.update_date || '',
            status: {
              code: leasing?.status?.code || '',
              name: leasing?.status?.name || ''
            },
            user: {
              firstName: leasing?.user.first_name || '',
              lastName: leasing?.user.last_name || ''
            }
          };
          return formattedValue;
        })
        .sort((a, b) => {
          return b?.createdDate?.localeCompare(a?.createdDate);
        })
    : [];
};
