import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButtonMui = ({
  title,
  width,
  height = '48px',
  padding = '16px 24px',
  disabled,
  onClick,
  endIcon,
  startIcon,
  action
}: any) => {
  const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    boxShadow: 'none',
    color: action === 'cancel' ? '#111827' : disabled ? '#A0AEC0' : '#FFFFFF',
    border: action === 'cancel' ? '1px solid #111827' : '',
    backgroundColor:
      action === 'cancel' ? '#FFFFFF' : disabled ? '#F1F2F4' : '#FF2E1F',
    borderRadius: '10px',
    fontWeight: 600,
    fontFamily: 'IBM Plex Sans',
    height: height,
    width: width,
    textTransform: 'none',
    padding: padding,
    fontSize: '14px',
    fontfamily: 'IBM Plex Sans',
    '&:hover': {
      boxShadow: 'none',
      border: 'none'
    }
  }));

  return (
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {title}
    </CustomButton>
  );
};
export default CustomButtonMui;
