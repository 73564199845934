import {
  pathAltynLogo,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo,
  shinhanPathLogo
} from '@/constants';

export const bankIconFormatter = (bankName: string) => {
  let icon;
  switch (bankName) {
    case 'halyk':
      icon = pathHalykLogo;
      break;
    case 'bcc':
      icon = pathBckLogo;
      break;
    case 'bereke':
      icon = pathBerekeLogo;
      break;
    case 'eu_bank':
      icon = pathEurasLogo;
      break;
    case 'shinhan':
      icon = shinhanPathLogo;
      break;
    case 'ffb':
      icon = pathFreedomlogo;
      break;
    case 'kmf':
      icon = pathKmfLogo;
      break;
    case 'forte':
      icon = pathForteLogo;
      break;
    case 'altyn':
      icon = pathAltynLogo;
      break;
    default:
      icon = '';
  }
  return icon;
};
