import { AxiosInstance } from 'axios';

import { LeasingServerResponseInterface } from '@/components/App/NewLeasing/leasings/types';

export class LeasingApi {
  static getAdminAllLeasing(
    limit: number | undefined,
    page: number | undefined,
    param: string | undefined,
    filterParam: string | undefined
  ) {
    throw new Error('Method not implemented.');
  }
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public getLeasingList() {
    return this.api.get<LeasingServerResponseInterface[]>('/leasings');
  }
}
