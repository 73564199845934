import { AxiosInstance } from 'axios';

export class IdeApi {
  constructor(private readonly api: AxiosInstance) {}

  public async getCode(param: string): Promise<{
    uuid: string;
    name: string;
    code: string;
    extension: string;
    is_external: string | null;
    interpreter: string;
  }> {
    const res = await this.api.get(`microservices/find/${param}`);
    return res.data;
  }
}
