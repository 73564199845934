import { leasingApi } from '@/integrations/index.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LeasingsInterface } from './types';
import { leasingsAdapter } from './adapters/leasingsAdapter';

const initialState: LeasingsInterface = {
  leasings: {
    data: [],
    status: 'idle',
    error: null
  }
};

export const fetchLeasings = createAsyncThunk(
  'leasings/fetchLeasings',
  async (_, {}) => {
    const response = await leasingApi.getLeasingList();
    const newDate = leasingsAdapter(response.data);
    return newDate;
  }
);

export const newLeasingSlice = createSlice({
  name: 'leasings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeasings.pending, (state) => {
        state.leasings.status = 'pending';
        state.leasings.error = null;
      })
      .addCase(fetchLeasings.fulfilled, (state, action) => {
        state.leasings.status = 'succeeded';
        state.leasings.data = action.payload;
      })
      .addCase(fetchLeasings.rejected, (state, action) => {
        state.leasings.status = 'failed';
        state.leasings.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      });
  }
});

export const {} = newLeasingSlice.actions;
