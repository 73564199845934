import { TCreateLitroDto, TLitroResponse } from '@/types/litro.type';

export const litroMapper = (item: TLitroResponse): TCreateLitroDto => {
  return {
    uuid: item.uuid,
    brand_uuid: item.brand_uuid || '',
    model_uuid: item.model_uuid || '',
    car_year: item.car_year || new Date().getFullYear().toString(),
    client_uuid: item.client_uuid,
    period_code: Number(item.period_code),
    car_registration: item.car_registration || null,
    card_name: item.card_name,
    city_code: (item.city?.code as string) || '',
    dealer_code: item.dealer_code,
    payment_type_code: item.payment_type_code || '',
    vin: item.vin || ''
  };
};
