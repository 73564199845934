import { FC, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { getLitro } from '@/store/litro.slice';
import { useAppDispatch, useAppSelector } from '@/store';

type TLitroSearch = {
  pagination: {
    limit: number;
    page: number;
  };
};

const { Search } = Input;

export const LitroSearch: FC<TLitroSearch> = ({ pagination }) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('true');

  useEffect(() => {
    dispatch(
      getLitro({
        limit: pagination.limit,
        page: pagination.page,
        param: searchParam,
        userRequests: selectedUser
      })
    );
  }, [pagination, searchQuery, searchParam, selectedUser]);
  return (
    <div
      className="table_editor"
      style={{
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Search
        style={{ width: '50%' }}
        size="large"
        placeholder="Поиск по ФИО / ИИН / Номер тел."
        // value={searchValue}
        // onChange={(e) => setSearchValue(e.target.value)}
        onSearch={setSearchParam}
      />

      <Select
        showSearch
        optionFilterProp={'label'}
        style={{ width: '22%' }}
        size="large"
        placeholder="Все заявки"
        value={selectedUser}
        onChange={setSelectedUser}
        options={
          [
            { value: 'true', label: 'Мои заявки' },
            { value: 'false', label: 'Все заявки' }
          ] || []
        }
        maxTagCount={'responsive'}
      />
    </div>
  );
};
