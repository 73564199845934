import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiConnector } from '../integrations/api.connector';
import {
  TRequest,
  TRequestForChartServerResponse,
  TRequestsAmountForChart,
  TTopFCForTable
} from '../types/request';
import { Dayjs } from 'dayjs';
import { TRequestStore } from '../types/store';
import { autoCredMapper } from '@/services/mappers/autoCredMapper';
import { requestsForChartMapper } from '@/services/mappers/requestsForChartMapper';

export const getRequests = createAsyncThunk(
  'request/getRequests',
  async (): Promise<TRequest[]> => {
    try {
      return await apiConnector.getRequests();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getAllRequestsForCharts = createAsyncThunk(
  'requests/getAllRequestsForCharts',
  async (): Promise<any[]> => {
    try {
      return await apiConnector.getAllRequestsForCharts();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const changeRequestStatus = createAsyncThunk(
  'request/changeRequestStatus',
  async (data: {
    reqId: string;
    newStatus:
      | 'draft'
      | 'approved'
      | 'declined'
      | 'mobile_client'
      | 'waiting_bank_response'
      | 'error'
      | 'financed'
      | 'cancelled'
      | 'waiting_of_financing'
      | 'sms_verificating'
      | 'accepted_alternative'
      | 'waiting_for_upload_files';
  }) => {
    try {
      return await apiConnector.changeRequestStatus(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

// export const getResponseByRequestId = createAsyncThunk(
//   'request/getResponseByRequestId',
//   async (id: string): Promise<any> => {
//     try {
//       const res = await apiConnector.getResponseByRequestId(id);
//       return res;
//     } catch (e: any) {
//       throw new Error(e);
//     }
//   }
// );

export const getUserFinanced = createAsyncThunk(
  `request/getUserReqsByStatus`,
  async (dateDealerInfo: {
    date_from: string;
    date_to: string;
    dealer_uuid: string;
  }): Promise<any[]> => {
    try {
      return await apiConnector.getUserReqsByStatus('financed', dateDealerInfo);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getTopFC = createAsyncThunk(
  'request/getTopFC',
  async (dateValue: Dayjs) => {
    try {
      return await apiConnector.getTopFc(dateValue);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getFilterByPersonal = createAsyncThunk(
  'requests/filters/userRequestsByParams',
  async (data: {
    bank_uuid?: string;
    status_code?: string;
    dealer_uuid?: string;
    model_uuid?: string;
    brand_uuid?: string;
    date_from?: string;
    date_until?: string;

  }) => {
    try {
      const response = await apiConnector.getFilterReqPersonal(
        data.bank_uuid,
        data.status_code,
        data.dealer_uuid,
        data.model_uuid,
        data.brand_uuid,
        data.date_from,
        data.date_until
      );
      return response;
    } catch (error) {
      // Обрабатываем ошибку корректно
      throw new Error('Failed to fetch filtered requests');
    }
  }
);

const initialState: TRequestStore = {
  requests: null,
  requestsForCharts: null,
  financedRequestsForCharts: null,
  topFC: null,
  isLoading: false
};

export const requestSlice = createSlice({
  name: 'request/getOtherUsersForFilter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequests.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requests = autoCredMapper(action.payload);
      })
      .addCase(getFilterByPersonal.fulfilled, (state, action) => {
        state.isLoading = false;
        //@ts-ignore
        state.requests = autoCredMapper(action.payload.data);
        //@ts-ignore
        //state.insurances = autoCredMapper(action.payload);
      })
      .addCase(getFilterByPersonal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFilterByPersonal.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(
        getAllRequestsForCharts.fulfilled,
        (state, action: PayloadAction<TRequestForChartServerResponse[]>) => {
          state.requestsForCharts = requestsForChartMapper(action.payload);
        }
      )
      .addCase(
        getTopFC.fulfilled,
        (state, action: PayloadAction<TTopFCForTable[]>) => {
          state.topFC = action.payload;
        }
      )

      .addCase(
        getUserFinanced.fulfilled,
        (state, action: PayloadAction<TRequestsAmountForChart[]>) => {
          state.financedRequestsForCharts = action.payload;
        }
      );
  }
});

export const {} = requestSlice.actions;
