import './date-picker-field.scss';

import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import {
  LocalizationProvider,
  ruRU,
  StaticDatePicker
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { debounce } from '@/services/helpers/debounce';
import CalendarIcon from '@/media/icons/calendar.svg?react';

import OutsideClickHandler from '../outside-click-handler';
import MaskedInput, { MaskedInputInterface } from '../masked-input';

type ChangedMaskedInputInterface = Omit<
  Omit<Omit<MaskedInputInterface, 'value'>, 'onChange'>,
  'mask'
>;

interface IProps extends ChangedMaskedInputInterface {
  value?: string;
  onChange?: (value: string) => void;
  viewFormat?: string;
  returnFormat?: string;
  mask?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableDays?: number;
}

const DatePickerField = forwardRef<HTMLInputElement, IProps>(
  (
    {
      value,
      onChange,
      viewFormat = 'DD.MM.YYYY',
      returnFormat = 'YYYY-MM-DD',
      mask = '__.__.____',
      disableFuture,
      disablePast,
      disableDays,
      isFrozen,
      ...rest
    },
    forwardRef
  ) => {
    const popup = useRef<HTMLDivElement>(null);

    const [topPosition, setTopPosition] = useState(false);
    const [focused, setFocused] = useState(false);

    const today = dayjs();
    const disableDate = (day: Dayjs) => {
      if (!disableDays) return false;
      if (disableFuture && day.isAfter(today.subtract(disableDays, 'days'))) {
        return true;
      }
      if (disablePast && day.isBefore(today.add(disableDays, 'days'))) {
        return true;
      }
      return false;
    };

    const handleScroll = useCallback(
      debounce(() => {
        // Disable setting position when popup opened
        if (focused) return;

        if (popup.current) {
          const position = popup.current.getBoundingClientRect();
          if (window.innerHeight - 350 < position.top) {
            setTopPosition(true);
          } else {
            setTopPosition(false);
          }

          if (position.left < window.innerWidth / 2) {
            popup.current.style.cssText = `
              left: 0;
              right: initial;
            `;
          } else {
            popup.current.style.cssText = `
              left: initial;
              right: 0;
            `;
          }
        }
      }, 300),
      [focused]
    );

    useEffect(() => {
      handleScroll();
      const main = document.querySelector('main');
      main?.addEventListener('scroll', handleScroll);
      return () => {
        main?.removeEventListener('scroll', handleScroll);
      };
    }, [popup, handleScroll]);

    useEffect(() => {
      if (value?.length === viewFormat.length) {
        const dateDifference = dayjs(value)
          .startOf('day')
          .diff(dayjs(new Date()).startOf('day'), 'days');
        if (disableFuture && dateDifference > 0)
          onChange?.(dayjs(new Date()).format(returnFormat));
        if (disablePast && dateDifference < 0)
          onChange?.(dayjs(new Date()).format(returnFormat));
      }
    }, [value]);

    useEffect(() => {
      if (value && value?.length > viewFormat.length) {
        const timestamp = Date.parse(value);
        if (Number.isNaN(timestamp)) return;
        const date = dayjs(value).format(returnFormat);
        onChange?.(date);
      }
    }, [value]);

    const formattedValue =
      value?.length === viewFormat.length
        ? dayjs(value).format(viewFormat)
        : value && value?.length <= mask.length
          ? value
          : '';

    if (viewFormat.length !== mask.length)
      return (
        <span>
          <b>format</b> and <b>mask</b> properties must be the same length
        </span>
      );

    return (
      <OutsideClickHandler onOutsideClick={() => setFocused(false)}>
        <div className="date-picker-field">
          <MaskedInput
            {...rest}
            type="tel"
            ref={forwardRef}
            value={formattedValue}
            onChange={(e) => {
              let value = e.target.value;
              if (value.length === viewFormat.length) {
                value = dayjs(value, viewFormat).format(returnFormat);
              }
              onChange?.(value);
            }}
            endContent={
              <span className="date-picker-field__icon">
                <CalendarIcon />
              </span>
            }
            onClick={(e) => {
              !isFrozen && setFocused((prev) => !prev);
              rest.onClick?.(e);
            }}
            mask={mask}
            replacement={rest.replacement || { _: /\d/ }}
            autoComplete="off"
            isFrozen={isFrozen}
          />

          <div
            ref={popup}
            className={`date-picker-field__popup ${
              focused ? 'date-picker-field__popup--shown' : ''
            } ${topPosition ? 'date-picker-field__popup--to-top' : ''}`}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={
                ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              }
              adapterLocale="ru"
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={
                  value?.length === viewFormat.length ? dayjs(value) : null
                }
                onChange={(value: Dayjs | null) => {
                  onChange?.(value ? value?.format(returnFormat) : '');
                  setFocused(false);
                }}
                disableFuture={disableFuture}
                disablePast={disablePast}
                shouldDisableDate={disableDate}
                showDaysOutsideCurrentMonth
              />
            </LocalizationProvider>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

DatePickerField.displayName = 'DatePickerField';

export default DatePickerField;
