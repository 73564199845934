import { validateNumberInputValue } from '@/services';
import { bankIconFormatter } from '@/services/formatter/bankIconFormatter';
import { TBankOffers } from '@/types/offers';

export const formatBackBankOfferResponse = (response: any): TBankOffers => {
  const carModel = response?.request?.model?.name || '';
  const carBrand = response?.request?.model?.brand?.name || '';
  const carYear = response?.request?.car_year || '';
  const icon = bankIconFormatter(response?.bank?.name || '');

  return {
    id: response?.uuid || '',
    offerId: response?.offered_uuid || '',
    responsePrimaryBody: response?.response_primary_body || '',
    bank: response?.bank?.name || '',
    name: response?.bank?.name || '',
    withPledge: response.with_pledge,
    extId: response?.ext_uuid || '',
    createDate: response?.create_date || '',
    responseApprovedBody: response?.response_approved_body || null,
    statusName: response?.status?.name || '',
    statusCode: response?.status?.uuid || '',
    rate: response?.rate || '',
    isOffered: response?.is_offered || false,
    bankDescription: response?.bank_description || '',
    monthlyPayment: validateNumberInputValue(
      response?.monthly_payment || '',
      12,
      true
    ),
    loanAmount: validateNumberInputValue(response?.loan_amount || '', 12, true),
    insurance_amount: validateNumberInputValue(
      response.insurance_amount,
      12,
      true
    ),
    initialFee: validateNumberInputValue(
      response?.down_payment || response?.initial_payment || '',
      12,
      true
    ),
    term: response?.loan_period || '',
    statusType: response?.status?.code || '',
    reason: response?.bank_description || '',
    alternative: [],
    product: `${carBrand} ${carModel}, ${carYear}`,
    icon: icon,
    responseSendedBody: response?.response_sended_body || '',
    productInfo: response?.request?.RequestsProducts
      ? response?.request?.RequestsProducts.find(
          (product: any) => product.product.bank_uuid === response.bank_uuid
        )
      : ''
  };
  // return bankOffer;
};
