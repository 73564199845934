import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TBank, TBankDto } from '@/types/bank.type';
import { banksApi } from '@/integrations/index.api';

type TBanksSlice = {
  banks: TBank[];
};

const initialState: TBanksSlice = { banks: [] };

export const getAllBanks = createAsyncThunk(
  'admin/banks/getAll',
  async (): Promise<TBank[]> => {
    try {
      const res = await banksApi.getAdminAllBanks();

      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createBank = createAsyncThunk(
  'admin/bank/create',
  async (data: TBankDto) => {
    const res = await banksApi.createAdminBank(data);
    return res;
  }
);

export const updateBank = createAsyncThunk(
  'admin/banks/update',
  async (data: TBank) => {
    const res = await banksApi.updateAdminBank(data);
  }
);
export const adminBanksSlice = createSlice({
  name: 'admin/banks',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBanks.fulfilled, (state, action) => {
      state.banks = action.payload;
    });
  }
});

export const {} = adminBanksSlice.actions;
