import './form-steps.scss';

import { checkIcon } from '@/constants';
import { Button } from '@/components/Shared/ui';

interface IProps {
  currentStep: number;
  steps: Array<{
    title: string;
    totalFields: number;
    validFields: number;
  }>;
  onChange: (step: number) => void;
  onCancel?: () => void;
  isNextClickable?: boolean;
}

const FormSteps: React.FC<IProps> = ({
  currentStep,
  steps,
  onChange,
  onCancel
}) => {
  const width = (100 * currentStep) / steps.length;

  return (
    <div className="form-steps">
      <div className="form-steps__header">
        <p>
          Шаг {currentStep} из {steps.length}
        </p>
      </div>
      <span
        // @ts-ignore
        style={{ '--width': width }}
        className="form-steps__progress"
      ></span>

      <div className="form-steps__body">
        {steps.map((step, i) => (
          <div
            className={`form-steps__step ${
              currentStep === i + 1 ? 'form-steps__step--active' : ''
            }`}
            key={step.title}
            onClick={() => onChange(i)}
          >
            {i + 1 < currentStep ? (
              <img className="form-steps__check" src={checkIcon} />
            ) : (
              <span className="form-steps__circle"></span>
            )}
            <p
              className={`form-steps__text ${
                currentStep < i + 1 ? 'form-steps__text--gray' : ''
              }`}
            >
              {step.title} {`(${step.validFields}/${step.totalFields})`}
            </p>
          </div>
        ))}
      </div>

      {onCancel && (
        <Button size="small" onClick={onCancel} className="form-steps__cancel">
          Отменить заявку
        </Button>
      )}
    </div>
  );
};

export default FormSteps;
