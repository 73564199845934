import { FC, useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { setDealer } from '@/store/userInfo.slice';
import { getActiveDealers, getDealersByUserId } from '@/store/dict.slice';
import { apiConnector } from '@/integrations/api.connector';

interface IPropSelectDealer {
  show: () => void;
}
const SelectDealerModal: FC<IPropSelectDealer> = ({ show }) => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const dealers = useAppSelector((state) => state.dict.activeDealersOption);
  const userDealers = useAppSelector((state) => state.dict.usersDealers);
  const dispatch = useAppDispatch();
  const [dealercode, setDealercode] = useState<string | null>(null);
  useEffect(() => {
    if (!dealers) {
      dispatch(getActiveDealers());
    }
  }, []);

  useEffect(() => {
    userInfo?.uuid && dispatch(getDealersByUserId(userInfo.uuid));
  }, [userInfo?.uuid]);

  const handleOk = () => {
    if (dealercode) {
      localStorage.setItem('dealer', dealercode.toString());
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: string;
          }
        | undefined =
        dealers && dealers.find((_dealer) => _dealer.code === dealercode);
      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
    show();
  };

  const handleChangeSelectDealer = (value: string) => {
    setDealercode(value);
  };

  return (
    <Modal
      centered
      title={<p style={{ fontSize: '18px' }}>Выберите дилерский центр</p>}
      open={true}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        style: {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '150%',
          color: '#fff',
          height: '56px',
          width: '182px',
          padding: '16px 24px',
          borderRadius: '10px',
          border: 'none',
          backgroundColor: '#ff2e1f'
        },
        disabled: !dealercode
      }}
      onOk={handleOk}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        style={{
          height: '40px',
          border: '1px solid #E9EAEC',
          borderRadius: '10px',
          width: '100%',
          marginBottom: '20px'
        }}
        options={
          userDealers.length > 0
            ? userDealers.map((dealer) => ({
                value: dealer.code,
                label: dealer.label
              }))
            : dealers
            ? dealers.map((dealer) => ({
                value: dealer.code,
                label: dealer.label
              }))
            : []
        }
        onChange={handleChangeSelectDealer}
      />
    </Modal>
  );
};

export default SelectDealerModal;
