import { AxiosInstance } from 'axios';

export class SqlExecutorApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async executeSQL(data: { query: string }): Promise<any> {
    const res = await this.api.post('admin/query/executeQuery', data);
    return res.data;
  }
}
