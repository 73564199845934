import { MuiTelInput } from 'mui-tel-input';

export const CustomPhoneInput = ({
  value,
  label,
  type,
  readOnly,
  onChangeInputHandler,
  disabled
}: {
  value: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  onChangeInputHandler: (val: string, label: string, type: string) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="customItem">
      <MuiTelInput
        disabled={disabled}
        value={value}
        autoComplete={'off'}
        defaultCountry="KZ"
        onChange={(val) => {
          if (!readOnly) {
            onChangeInputHandler(val, label, type!);
          }
        }}
        label={
          <p>
            {label === 'workTelNumber' ? 'Рабочий ' : ''}
            {type === 'contactPhoneNumber' ? 'Контактный ' : ''}
            {type === 'additionalPhoneNumber' ? 'Дополнительный ' : ''}
            Номер Телефона
            <span style={{ color: '#E03137' }}>*</span>
          </p>
        }
        style={{ width: '100%' }}
        InputProps={{ readOnly: readOnly }}
      />
    </div>
  );
};
