import { Spin } from 'antd';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const SuspenseRoute: React.FC = () => {
  return (
    <Suspense fallback={<Spin fullscreen />}>
      <Outlet />
    </Suspense>
  );
};

export default SuspenseRoute;
