import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { apiConnector } from '@/integrations/api.connector';
import { dispatch, useAppSelector } from '@/store';
import { tokenValidate } from '@/store/auth.slice';
import { getUserAccount } from '@/store/userInfo.slice';

const UNAUTHORIZED_ROUTES = ['/login', '/reset_password', '/approvements'];

const CheckAuth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserAuth } = useAppSelector((state) => state.auth);

  const { dealer } = useAppSelector((state) => state.userInfo);

  useEffect(() => {
    dispatch(tokenValidate());
    setInterval(async () => {
      await apiConnector.refreshToken();
    }, 180000);
  }, []);

  useEffect(() => {
    if (!isUserAuth) {
      const shouldRedirect = UNAUTHORIZED_ROUTES.find((item) =>
        location.pathname.includes(item)
      );
      if (!shouldRedirect) navigate('/login');
    } else if (isUserAuth) dispatch(getUserAccount());
  }, [isUserAuth]);

  useEffect(() => {
    if (
      dealer &&
      location.pathname.includes('/pos-credit') &&
      Number(dealer?.code) !== 1016
    ) {
      navigate('/');
    }
  }, [dealer, location.pathname, navigate]);

  useEffect(() => {
    dispatch(tokenValidate());
  }, []);

  return <Outlet />;
};

export default CheckAuth;
