import { TUserDto, TUserUpdateDto } from '@/types/user.type';

export const mapUserData = (item: TUserDto): TUserUpdateDto => {
  return {
    login: item.login || '',
    email: item.email || '',
    lastName: item.last_name || '',
    firstName: item.first_name || '',
    middleName: item.middle_name || '',
    phoneNumber: item.phone_number,
    birthDate: item.birth_date,
    positionId: item.position.uuid,
    salePointId: item.sale_point?.uuid,
    isActive: item.is_active,
    iin: item.user_iin,
    workDateStart: item.work_date_start,
    dealerIds: item.Users_Dealers?.map((users) => users.dealer_uuid) || []
  };
};
