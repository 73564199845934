export const checkObjectValues = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if (!checkObjectValues(value)) return false;
      } else {
        if (
          key !== 'spouseAddSalary' &&
          key !== 'spouseCompanyName' &&
          key !== 'houseNumResidence' &&
          key !== 'appartmentNumJob' &&
          key !== 'villageSettlement'
        ) {
          return !(
            value === '' ||
            value === undefined ||
            value === null ||
            value === false
          );
        } else {
          return true;
        }
      }
    }
  }
  return true;
};
