import deletebutton from './media/icons/Button.png';
import filterIcon from './media/icons/Filter.png';
import searchIcon from './media/icons/Search.png';
import altynLogo from './media/icons/altyn_logo.png';
import bckLogo from './media/icons/bck_Logo.png';
import bellIcon from './media/icons/bell.png';
import berekeLogo from './media/icons/bereke_logo.png';
import calcIcon from './media/icons/calc.png';
import calculatorPlaceholder from './media/icons/calculatorPlaceholder.png';
import cameraIcon from './media/icons/camera_icon.png';
import carIcon from './media/icons/car.png';
import check from './media/icons/check.svg';
import chevronDown from './media/icons/chevron-down.png';
import clipBoardIcon from './media/icons/clipboard-tick.png';
import darkInfoCircle from './media/icons/dark-info-circle.png';
import darkMinus from './media/icons/dark-minus.png';
import darkPlus from './media/icons/dark-plus.png';
import penIcon from './media/icons/edit_pen_icon.png';
import eurasLogo from './media/icons/euras_logo.png';
import fetchPolicies from './media/icons/fetch-policies.svg?react';
import fileDownload from './media/icons/file-download.svg';
import {
  default as fileText,
  default as filetextIcon
} from './media/icons/file-text.png';
import forteLogo from './media/icons/forte_logo.png';
import freedomlogo from './media/icons/freedom_Logo.png';
import halykLogo from './media/icons/halyk_logo.png';
import infoGrey from './media/icons/info-circle.png';
import info from './media/icons/info.png';
import kmfLogo from './media/icons/kmf_logo.png';
import layoutIcon from './media/icons/layout-grid.png';
import nur from './media/icons/nur.svg';
import percentageIcon from './media/icons/percentage-square.png';
import phone from './media/icons/phone.svg';
import photoIcon from './media/icons/photo_icon.png';
import plus from './media/icons/plus.svg';
import pndIcon from './media/icons/pnd_icon.png';
import posIcon from './media/icons/pos.png';
import printer from './media/icons/printer.png';
import questionIcon from './media/icons/question-circle-outlined.png';
import redDownload from './media/icons/red_download.png';
import settingIcon from './media/icons/settings.png';
import shieldIcon from './media/icons/shield-tick.png';
import shinHanLogo from './media/icons/shinhan-bank.png';
import trashIcon from './media/icons/trash_icon.png';
import usersIcon from './media/icons/users.png';
import warning from './media/icons/warning-fill.png';
import closeXicon from './media/icons/x.png';
import logoBg from './media/login_bg.png';
import AllurFinanceLogo from './media/logo.png';
import expandLogo from './media/logo_expand.png';
import logoAllurRed from './media/svg/logo_red.svg';

export const pathLogo = AllurFinanceLogo;

export const pathCalculatorPlaceholder = calculatorPlaceholder;
export const pathExpandLogo = expandLogo;
export const pathSettingIcon = settingIcon;
export const pathQuestionicon = questionIcon;
export const pathBellIcon = bellIcon;
export const pathCalcIcon = calcIcon;
export const pathFilterIcon = filterIcon;
export const pathSearchIcon = searchIcon;
export const pathCloseXIcon = closeXicon;
export const pathFreedomlogo = freedomlogo;
export const pathBckLogo = bckLogo;
export const pathBerekeLogo = berekeLogo;
export const pathAltynLogo = altynLogo;
export const pathEurasLogo = eurasLogo;
export const pathForteLogo = forteLogo;
export const pathHalykLogo = halykLogo;
export const pathKmfLogo = kmfLogo;
export const shinhanPathLogo = shinHanLogo;
export const pathLogoBg = logoBg;
export const pathLogoAllurRed = logoAllurRed;
export const pathTrashIcon = trashIcon;
export const pathPhotoIcon = photoIcon;
export const pathCameraIcon = cameraIcon;
export const infoIcon = info;
export const greyInfoIcon = infoGrey;
export const pathFileText = fileText;
export const pathRedDownload = redDownload;
export const pathPenIcon = penIcon;
export const chevronDownIcon = chevronDown;
export const printerLogo = printer;
export const darkInfoCircleLogo = darkInfoCircle;
export const darkPlusIcon = darkPlus;
export const darkMinusIcon = darkMinus;
export const warningIcon = warning;
export const plusIcon = plus;
export const checkIcon = check;
export const pathButtonIcon = deletebutton;
export const phoneIcon = phone;
export const fetchPoliciesIcon = fetchPolicies;
export const fileDownloadIcon = fileDownload;

// export const baselicon = baselicon;

export const sideBarItems: {
  label: string;
  key: string;
  iconPath: string;
  rolesPermitted: string[];
}[] = [
  { label: 'Главная', key: 'main', iconPath: layoutIcon, rolesPermitted: [] },
  {
    label: 'Автокредитование',
    key: 'auto-credit',
    iconPath: carIcon,
    rolesPermitted: []
  },
  {
    label: 'Страхование',
    key: 'insurance',
    iconPath: shieldIcon,
    rolesPermitted: []
  },
  {
    label: 'POS-кредитование',
    key: 'pos-cred',
    iconPath: posIcon,
    rolesPermitted: []
  },
  {
    label: 'Урегулирование',
    key: 'settlement',
    iconPath: clipBoardIcon,
    rolesPermitted: []
  },
  {
    label: 'Лизинг',
    key: 'leasing',
    iconPath: percentageIcon,
    rolesPermitted: []
  },
  { label: 'Клиенты', key: 'clients', iconPath: usersIcon, rolesPermitted: [] },
  {
    label: 'Доп. продукт (ПНД)',
    key: 'additional-product-pnd',
    iconPath: pndIcon,
    rolesPermitted: []
  },
  {
    label: 'Отчеты',
    key: 'reports',
    iconPath: filetextIcon,
    rolesPermitted: []
  }
];

export enum DictTypes {
  PropertyTypes = 'Property_Types', //Вид собственности
  ProductsTypes = 'Product_Types', //Типы продуктов
  MainActivities = 'Main_Activities', //Основной вид деятельности
  DocumentIssuringAuthorities = 'Document_Issuing_Authorities', // Кем выдан
  DocumentTypes = 'Document_Types', // Тип документа
  ResidentStatuses = 'Resident_Statuses', // Статус резидента
  MaritalStatuses = 'Marital_Statuses', // Семейное положение
  SocialStatuses = 'Social_Statuses', // Социальный статус
  OrganizationTypes = 'Organization_Types', // Вид организации
  Insurances = 'Insurances', //Страховые компании
  EducationTypes = 'Education_Types', // Образование
  // PropertyTypes = 'Property_Types', // Тип дома
  CompanyActivityTypes = 'Company_Activity_Types', // Сфера деятельности организации
  RepaymentTypes = 'Repayment_Types', // Схема оплаты
  Statuses = 'Statuses', // Статусы
  VehicleRegistrationCompanies = 'Vehicle_Registration_Companies',
  Genders = 'Genders', // Пол
  LoanTerms = 'Loan_Terms', // Срок займа
  EngineTypes = 'Engine_Types', // Тип двигателя
  Dealers = 'Dealers', // Диллеры
  AutoTypes = 'Auto_Types', // Тип авто
  PaymentDates = 'Payment_Dates', // Дата платежа
  Colors = 'Colors', // Цвет авто
  AddContactsIsRelated = 'Add_Contacts_Is_Related', // Кем приходится
  Brands = 'Brands', // Марка
  Models = 'Models', // Модель
  Years = 'Years', // Год выпуска
  Countries = 'Countries', //Страны
  Products = 'Products', //Продукты (StockBoundaries)
  Banks = 'Banks', // Интегрированные Банки
  InsurancePayment = 'Insurance_Payment_Types', // способ оплаты
  InsuranсeInstallmentTypes = 'Insurance_Installment_Types', // тип оплаты
  Insurance_Installment_Periods = 'Insurance_Installment_Periods', // период рассрочки
  //InsurancePayment = 'Insurance_Payment_Type', // выбор оплты страхования
  ProductTypes = 'Product_Types', // Тип продукта
  PoscredProducts = 'Poscred_Products', // Тип Pos-cred продуктов
  Poscred_Bank_Products = 'Poscred_Bank_Products', // Продукт БВУ

  Cities = 'Cities' // Список городов
}

export const statusColors = {
  draft: {
    bgColor: '#313A49',
    textColor: 'white'
  },
  created: {
    bgColor: '#313A49',
    textColor: 'white'
  },
  approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  completed: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  declined: {
    bgColor: '#E03137',
    textColor: 'white'
  },
  waiting_bank_response: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  waiting_of_payment: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  error: {
    bgColor: '#FF2E1F',
    textColor: 'white'
  },
  financed: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  cancelled: {
    bgColor: '#FF2E1F',
    textColor: 'white'
  },
  waiting_of_financing: {
    bgColor: '#1f9aff',
    textColor: 'white'
  },
  waiting_for_upload_files: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  accepted_alternative: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  on_rework: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  on_verification: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  financed_pledge_missing: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  sms_verificating: {
    bgColor: '#023D3D',
    textColor: 'white'
  },
  pre_approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  mobile_client: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  financed_waiting_for_modification_before_pledge: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  }
};

export enum BanksColors {
  FreedomBank = '#85B447',
  EurasBank = '#EA346F',
  HalykBank = '#E6BB20',
  BccBank = '#DAAB6B',
  ForteBank = '#4BA9B8',
  KMFBank = '#D4310F',
  BerekeBank = '#2DD4BF'
}

export const BANKS_ICONS_NAMES = {
  halyk: { name: 'Халык Банк', icon: pathHalykLogo },
  kmf: { name: 'KMF', icon: pathKmfLogo },
  bereke: { name: 'Береке Банк', icon: pathBerekeLogo },
  shinhan: { name: 'Shinhan Банк', icon: shinhanPathLogo },
  bcc: { name: 'Банк Центр Кредит', icon: pathBckLogo },
  sapa: { name: 'SAPA', icon: '' },
  altyn: { name: 'Алтын Банк', icon: pathAltynLogo },
  ffb: { name: 'Freedom Finance Bank', icon: pathFreedomlogo },
  forte: { name: 'Forte Bank', icon: pathForteLogo },
  eu_bank: { name: 'Банк Евразийский', icon: pathEurasLogo },
  nur: { name: '', icon: nur }
};

export enum SystemActions {
  CREATE_REQUEST = 1,
  VIEW_ALL_REQUESTS,
  ROLE_MANAGMENT
}

export const REGISTRATION_DUTY_COST = 46500;

export enum CALCULATION_TYPES {
  STANDART_ANUETET = 'Аннуитет',
  STANDART_DIFERENCE = 'Дифференцированный',
  SUBSIDES = 'Субсидии'
}

export enum ADDITIONAL_FILES_TYPES {
  SPOUSE_AGREEMENT = 'spouseConsent'
}
