import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { BanksApi } from '@/integrations/adminApi/banksApi';
import { ClientsApi } from '@/integrations/adminApi/clientsApi';
import { DealersApi } from '@/integrations/adminApi/dealersApi';
import { IdeApi } from '@/integrations/adminApi/ideApi';
import { LogsApi } from '@/integrations/adminApi/logsApi';
import { ModelsApi } from '@/integrations/adminApi/modelsApi';
import { PositionsApi } from '@/integrations/adminApi/positionsApi';
import { ProductsApi } from '@/integrations/adminApi/productsApi';
import { AuthAdminApi } from '@/integrations/adminApi/refreshApi';
import { RolesActionsApi } from '@/integrations/adminApi/rolesActionsApi';
import { SalePointsApi } from '@/integrations/adminApi/salePointsApi';
import { SqlExecutorApi } from '@/integrations/adminApi/sqlExecutorApi';
import { UsersApi } from '@/integrations/adminApi/usersApi';
import { DownloadFilesApi } from '@/integrations/downloadFilesApi';
import { LitroApi } from '@/integrations/litroApi';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { store, useAppDispatch } from '@/store';

import { LeasingAdminApi } from './adminApi/leasingAdminApi';
import { LeasingApi } from './adminApi/leasingApi';
import { ModulesApi } from './adminApi/modulesApi';
import { PosCredApi } from './adminApi/posCredApi';

let appDispatch: ReturnType<typeof useAppDispatch>;
let isRefreshing = false;

export const appInjectStore = () => {
  appDispatch = store.dispatch;
};
export const indexApi = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

export const ideAxios = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

indexApi.interceptors.request.use((config) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {}
  return config;
});

export const refreshTokenAxios = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

refreshTokenAxios.interceptors.request.use((config) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    } else {
      // localStorage.clear();
      clearLocalStorageHelper();
    }
  } catch (e) {}
  return config;
});

indexApi.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    try {
      const originalRequest = error.config as InternalAxiosRequestConfig;

      if (
        (error.response && error.response.status === 403) ||
        error.response?.status === 401
      ) {
        if (!isRefreshing) {
          isRefreshing = true;

          const newResponse = await adminRefreshApi.refreshToken();
          if (newResponse.status === 200) {
            localStorage.setItem('accessToken', newResponse.data.accessToken);
            localStorage.setItem('refreshToken', newResponse.data.refreshToken);
            const newToken = localStorage.getItem('accessToken');
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            isRefreshing = false;
            return indexApi(originalRequest);
          }
        }
      }
      isRefreshing = false;
      // localStorage.clear();
      // window.location.reload();
      return Promise.reject(error);
    } catch (e) {
      isRefreshing = false;
      // localStorage.clear();
      clearLocalStorageHelper();

      return Promise.reject(error);
    }
  }
);

export const downloadFilesApi = new DownloadFilesApi(indexApi);

export const adminRefreshApi = new AuthAdminApi(refreshTokenAxios);
export const dealersApi = new DealersApi(indexApi);
export const logsApi = new LogsApi(indexApi);
export const clientsApi = new ClientsApi(indexApi);
export const productsApi = new ProductsApi(indexApi);
export const modelsApi = new ModelsApi(indexApi);
export const banksApi = new BanksApi(indexApi);
export const userApi = new UsersApi(indexApi);
export const sqlExecutor = new SqlExecutorApi(indexApi);
export const positionsApi = new PositionsApi(indexApi);
export const salePointsApi = new SalePointsApi(indexApi);
export const posCredApi = new PosCredApi(indexApi);
export const rolesActionsApi = new RolesActionsApi(indexApi);
export const ideApi = new IdeApi(ideAxios);
export const litroApi = new LitroApi(indexApi);
export const leasingApi = new LeasingApi(indexApi);
export const modulesApi = new ModulesApi(indexApi);
export const leasingAdminApi = new LeasingAdminApi(indexApi);
