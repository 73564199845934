import { TLeasingInfo, TServerLeasingsResponse } from '@/types/leasing';

export const leasingItemMapper = (
  leasing: TServerLeasingsResponse
): TLeasingInfo => {
  return {
    uuid: leasing?.uuid || '',
    createdDate: leasing?.create_date || '',
    updatedDate: leasing?.update_date || '',
    companyName: leasing?.company_name || '',
    iin: leasing?.iin || '',
    totalAmount: leasing?.total_amount || null,
    initialPayment: leasing?.initial_payment || null,
    period: leasing?.period || null,
    comment: leasing?.comment,
    isAlternativeOffer: leasing?.is_alternative_offer || false,
    clientFullName: leasing?.client_full_name || '',
    birthDate: leasing?.birth_date || '',
    licenseMainActivity: leasing.license_main_activity,
    registrationCountry: leasing?.registration_country || null,
    workStartDate: leasing?.work_start_date || '',
    stateRegistrationNumber: leasing?.state_registration_number || '',
    stateRegistrationDate: leasing?.state_registration_date || '',
    propertyType: leasing?.property_type || null,
    placeOfRegistration: leasing?.place_of_registration || null,
    okedOfMainAndSubActivity: leasing?.oked_of_main_and_sub_activity || '',
    mainActivity: leasing?.main_activity || null,
    livingAddress: leasing?.living_address || '',
    licenseOrganizationAuthority: leasing?.license_organization_authority || '',
    licenseInformation: leasing?.license_information || '',
    licenseNum: leasing?.license_num || '',
    legalAddress: leasing?.legal_address || '',
    firstSupervisorFullName: leasing?.first_supervisor_fullName || '',
    email: leasing?.email || '',
    firstRegistrationDate: leasing?.first_registration_date || '',
    documentValidEndDate: leasing?.document_valid_end_date || '',
    documentNumber: leasing?.document_number || '',
    documentIssueDate: leasing?.document_issue_date || '',
    docName: leasing?.doc_name || null,
    contactPhoneNumber: leasing?.contact_phone_number || '',
    bankRequisits: leasing?.bank_requisits || '',
    additionalPhoneNumber: leasing?.additional_phone_number || '',
    actualAddress: leasing?.actual_address || '',
    status: leasing?.status || '',
    code: leasing?.code,
    leasingSubjects: leasing?.Leasing_Subjects || [],
    responses:
      leasing.responses.map((item) => ({
        uuid: item.uuid,
        leasingUuid: item.leasing_uuid,
        leasingCompany: item.Leasing_Company,
        leasingCompanyUuid: item.leasing_company_uuid,
        leasingResponsesHistory: item.Leasing_Responses_History.map(
          (history) => ({
            uuid: history.uuid,
            responseUuid: history.response_uuid,
            fromStatus: history.from_status,
            toStatus: history.to_status,
            comment: history.comment,
            user: history.user
              ? {
                  lastName: history.user.last_name,
                  firstName: history.user.first_name,
                  middleName: history.user.middle_name,
                  phoneNumber: history.user.phone_number,
                  email: history.user.email
                }
              : null,
            createdAt: history.created_at
          })
        ),
        approvedAmount: item.approved_amount,
        initialPayment: item.initial_payment,
        comment: item.comment,
        isAlternativeOffer: item.is_alternative_offer,
        status: item.status,
        statusUuid: item.status_uuid
      })) || [],
    user: leasing?.user
      ? {
          uuid: leasing.user.uuid,
          firstName: leasing.user.first_name,
          lastName: leasing.user.last_name,
          middleName: leasing.user.middle_name,
          email: leasing.user.uuid
        }
      : null
  };
};
