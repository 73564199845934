import {
  PosCredUserApplicationInterface,
  ServerPosCredApplicationInterface
} from '../types/slice-types';

export const posCredUserAdapter = (
  data: ServerPosCredApplicationInterface
): PosCredUserApplicationInterface => {
  // Convert server data to client-friendly format
  return {
    uuid: data.uuid,
    status: data.status,
    iin: data.client.client_iin,
    fullName: data.client.first_name + ' ' + data.client.last_name,
    orderCode: data.code,
    phoneNumber: data.client.phone_number,
    product: data.product,
    productBank: data.product_bank,
    productDc: data.product_dc,
    productBankUuid: data.product_bank_uuid,
    loanAmount: data.loan_amount || '',
    loanTerm: Number(data.loan_term || 0),
    bankResponse: data.responses || null
  };
};
