import { FC } from 'react';

type TStatusCellProps = {
  className: string;
  text: string;
};
export const StatusCell: FC<TStatusCellProps> = ({ className, text }) => {
  return (
    <div className={className}>
      <p className="status-text">{text.toUpperCase()}</p>
    </div>
  );
};
