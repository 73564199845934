// export const checkVerifyInputValuesValid = (inputValues: {
//   iin: string;
//   fullName?: string;
//   telNum: string;
// }) => {
//   if (
//     inputValues.iin.length < 12 ||
//     inputValues?.fullName?.trim() === '' ||
//     inputValues.telNum.length < 16
//   ) {
//     return false;
//   } else {
//     return true;
//   }

import { cleanPhoneNumber } from '../formatter';

// };
export const checkVerifyInputValuesValid = (inputValues: {
  iin: string;
  fullName?: string;
  telNum: string;
}) => {
  return (
    inputValues.iin.length === 12 &&
    inputValues?.fullName?.trim() !== '' &&
    cleanPhoneNumber(inputValues.telNum).length >= '71112223344'.length
  );
};
