import { DownOutlined } from '@ant-design/icons';
import { Avatar, Badge, Dropdown, MenuProps, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { pathBellIcon } from '@/constants';
import { getPageTitle } from '@/services';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { useAppDispatch, useAppSelector } from '@/store';
import { setIsAuth } from '@/store/auth.slice';
import {
  getActiveDealers,
  getOptimizedDictionaries,
  getProductInsurance,
  getProductsDictionary
} from '@/store/dict.slice';
import { onToggleShowNotifications } from '@/store/notifications.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { setJwtDecodedInfo } from '@/store/userInfo.slice';
import { DecodedJWTToken } from '@/types/user';

import NotificationsSideBar from '../Notifications/NotificationsSideBar';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lastNotificationsChecked } = useAppSelector(
    (state) => state.notifications
  );
  const pageTitle = useAppSelector((state) => state.newOrder.pageTitle);
  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const [user, setUser] = useState<{
    lastName: string;
    firstName: string;
    actions: number[];
  }>({
    firstName: '',
    lastName: '',
    actions: []
  });

  const items: MenuProps['items'] = [
    {
      key: 'personal',
      label: <p>Личный кабинет</p>
    },
    ...(user.actions.includes(0)
      ? [
          {
            key: 'adminPage',
            label: (
              <p>
                {/*<a style={{ color: 'rgba(0, 0, 0, 0.88)' }} href="https://google.com">*/}
                Панель администратора
                {/*</a>*/}
              </p>
            )
          }
        ]
      : []),
    {
      key: 'logout',
      label: <p>Выйти</p>
    }
  ];

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const data = jwtDecode<DecodedJWTToken>(accessToken || '');
      setUser((prevState) => ({
        ...prevState,
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        actions: data?.actions || []
      }));
      dispatch(setJwtDecodedInfo(data));
      dispatch(getOptimizedDictionaries());
      dispatch(getProductsDictionary());
      dispatch(getActiveDealers());
      dispatch(getProductInsurance());
    }
  }, []);

  const onClickItems: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      clearLocalStorageHelper();
      dispatch(setIsAuth(false));
      navigate('/login');
    } else {
      navigate(`/${key}`);
    }
  };

  const isCalculator = location.pathname === '/calculator';

  return (
    <header>
      <div>
        <div
          className="title"
          style={
            location.pathname.includes('/auto-credit/')
              ? { marginLeft: '45px' }
              : {}
          }
        >
          <h2>{getPageTitle(location.pathname) || pageTitle}</h2>

          {/*Стрелка назад*/}
          {location.pathname.includes('/auto-credit/') ||
          location.pathname.includes('/clients/') ||
          location.pathname.includes('/pos-credit/') ||
          location.pathname.includes('/insurances/') ||
          location.pathname.includes('/leasing/') ||
          location.pathname.includes('/adminPage/dealers/create') ||
          (location.pathname.includes('/adminPage/dealers') &&
            location.pathname.includes('/findById')) ||
          location.pathname.includes('/adminPage/users/create') ||
          (location.pathname.includes('/adminPage/users/') &&
            location.pathname.includes('/update')) ||
          location.pathname.includes('/adminPage/position/create') ||
          (location.pathname.includes('/adminPage/position') &&
            location.pathname.includes('/update')) ||
          location.pathname.includes('/adminPage/roles_action/create') ||
          (location.pathname.includes('/adminPage/roles_action') &&
            location.pathname.includes('/update')) ||
          location.pathname.includes('/adminPage/modules/create') ||
          (location.pathname.includes('/adminPage/modules/') &&
            location.pathname.includes('/modules/')) ||
          isCalculator ? (
            <p
              onClick={() => {
                dispatch(resetReqSuccessIndicator());
                navigate(-1);
              }}
            >
              Назад
            </p>
          ) : null}

          {/*  город в котором работает?*/}
          {location.pathname === '/' && (
            <h2 className="city_info">г. Алматы</h2>
          )}
        </div>
        <div className="pagination">
          <p style={{ marginRight: '10px' }}>{dealer?.label}</p>
          <div className="lang">RU</div>
          <Badge dot={!lastNotificationsChecked} offset={[-10, 4]}>
            <button
              className="bell_btn"
              onClick={() => dispatch(onToggleShowNotifications())}
            >
              <img src={pathBellIcon} alt="bell_icon" />
            </button>
          </Badge>
          <Dropdown
            menu={{ items, onClick: onClickItems }}
            placement="bottomRight"
            arrow
          >
            <Space style={{ cursor: 'pointer' }}>
              <Avatar style={{ backgroundColor: 'black' }}>
                {user.firstName[0]?.toUpperCase()}
                {user.lastName[0]?.toUpperCase()}
              </Avatar>{' '}
              <p className="user_name">
                {user.firstName} {user.lastName}
              </p>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
        <NotificationsSideBar />
      </div>
    </header>
  );
};
