import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import { newLeasingSlice } from '@/components/App/NewLeasing/leasings/slice';
import { posCredApplicationSlice } from '@/components/App/PosCred/PosCredApclication/slice';
import { posCredSlice } from '@/components/App/PosCred/PosCreds/slice';
import { wsMiddleware } from '@/integrations/socket.connector';
import { adminSlice } from '@/store/admin/admin.slice';
import { adminBanksSlice } from '@/store/admin/adminBanks.slice';
import { adminClientsSlice } from '@/store/admin/adminClientsSlice';
import { adminDealersSlice } from '@/store/admin/adminDealers.slice';
import { adminLogsSlice } from '@/store/admin/adminLogs.slice';
import { adminModelsSlice } from '@/store/admin/adminModels.slice';
import { adminProductsSlice } from '@/store/admin/adminProducts.slice';
import { adminPromoProductModelSlice } from '@/store/admin/adminPromoProductModel.slice';
import { adminUsersSlice } from '@/store/admin/adminUsers.slice';
import { litroSlice } from '@/store/litro.slice';

import { adminLeasingSlice } from './admin/adminLeasing.slice';
import adminModulesSlice from './admin/adminModules.slice';
import { adminExecutorSqlSlice } from './admin/executorSql.slice';
import { appSlice } from './app.slice';
import { authSlice } from './auth.slice';
import { clientsSlice } from './clients.slice';
import { dictSlice } from './dict.slice';
import { financingStepSlice } from './financingStep.slice';
import { leasingsSlice } from './leasings.slice';
import { newOrderSlice } from './newOrder.slice';
import { notificationsSlice } from './notifications.slice';
import { offerStepSlice } from './offerStep.slice';
import { orderInsuranceSlice } from './orderInsurance.slice';
import { orderStepSlice } from './orderStep.slice';
import { requestSlice } from './request.slice';
import { templatesSlice } from './templates.slice';
import { userInfoSlice } from './userInfo.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth'
    // 'newOrder',
    // 'offerStep',
    // 'orderStepSlice',
    // 'financingStep',
    // 'dict'
  ]
};

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  newOrder: newOrderSlice.reducer,
  orderStep: orderStepSlice.reducer,
  orderInsurance: orderInsuranceSlice.reducer,
  offerStep: offerStepSlice.reducer,
  financingStep: financingStepSlice.reducer,
  dict: dictSlice.reducer,
  request: requestSlice.reducer,
  userInfo: userInfoSlice.reducer,
  clients: clientsSlice.reducer,
  templates: templatesSlice.reducer,
  notifications: notificationsSlice.reducer,
  leasings: leasingsSlice.reducer,
  app: appSlice.reducer,
  litro: litroSlice.reducer,
  admin: adminSlice.reducer,
  adminDealers: adminDealersSlice.reducer,
  adminModels: adminModelsSlice.reducer,
  adminProduct: adminProductsSlice.reducer,
  adminPromoProductModel: adminPromoProductModelSlice.reducer,
  adminClients: adminClientsSlice.reducer,
  adminLeasing: adminLeasingSlice.reducer,
  adminUsers: adminUsersSlice.reducer,
  adminBanks: adminBanksSlice.reducer,
  adminLogs: adminLogsSlice.reducer,
  adminExecutor: adminExecutorSqlSlice.reducer,
  posCred: posCredSlice.reducer,
  posCredApplication: posCredApplicationSlice.reducer,
  newLeasings: newLeasingSlice.reducer,
  adminModules: adminModulesSlice.reducer
});
const middleware = [thunk, wsMiddleware];
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(middleware)
});

export const persistor = persistStore(store);

export const dispatch = store.dispatch.bind(store);
export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

export const useAppDispatch: () => TAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;
