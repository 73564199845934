import { useRef, useState } from 'react';

import { IMultiselectOptions } from '../multiselect';

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  PAGE_DOWN: 34,
  ESCAPE: 27,
  PAGE_UP: 33,
  ENTER: 13
};

interface IProps {
  selectedValues?: IMultiselectOptions[];
  onChange: (
    value: IMultiselectOptions,
    selectedValues: IMultiselectOptions[]
  ) => void;
  defaultOptions: IMultiselectOptions[];
  setFocused: (value: boolean) => void;
}

const useSelect = ({
  selectedValues,
  onChange,
  defaultOptions,
  setFocused
}: IProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  function selectOption(index: number) {
    if (index > -1) {
      const isValueExists = selectedValues?.find(
        (item) => item.value === defaultOptions[index].value
      );
      const list = selectedValues?.filter(
        (item) => item.value !== isValueExists?.value
      );

      const newSelectedValues = list ?? [];
      if (!isValueExists) newSelectedValues.push(defaultOptions[index]);
      onChange(defaultOptions[index], newSelectedValues);
    }
  }

  const optionHeight = listRef?.current?.children[0]?.clientHeight || 0;

  function scrollUp() {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else if (selectedIndex === -1 || selectedIndex === 0) {
      setSelectedIndex(defaultOptions.length - 1);
    }
    if (listRef?.current?.scrollTop !== undefined)
      listRef.current.scrollTop =
        selectedIndex === -1 || selectedIndex === 0
          ? listRef.current.scrollTop + optionHeight * defaultOptions.length
          : listRef.current.scrollTop - optionHeight;
  }

  function scrollDown() {
    if (selectedIndex < defaultOptions.length - 1 || selectedIndex === -1) {
      setSelectedIndex(selectedIndex + 1);
    } else if (selectedIndex === defaultOptions.length - 1) {
      setSelectedIndex(0);
    }
    if (listRef?.current?.scrollTop !== undefined)
      listRef.current.scrollTop =
        selectedIndex === defaultOptions.length - 1
          ? 0
          : selectedIndex * optionHeight;
  }

  function pageDown() {
    setSelectedIndex(defaultOptions.length - 1);
    if (listRef?.current?.scrollTop)
      listRef.current.scrollTop = defaultOptions.length * optionHeight;
  }

  function pageUp() {
    setSelectedIndex(0);
    if (listRef?.current?.scrollTop) listRef.current.scrollTop = 0;
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      [KEY_CODES.ENTER]: () => selectOption(selectedIndex),
      [KEY_CODES.ESCAPE]: () => setFocused(false),
      [KEY_CODES.PAGE_DOWN]: pageDown,
      [KEY_CODES.PAGE_UP]: pageUp
    };
    if (keyOperation[e.keyCode]) {
      keyOperation[e.keyCode]();
    } else {
      setSelectedIndex(-1);
    }
  }

  return {
    bindOption: {
      onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const nodes = Array.from(listRef.current?.children || []);
        // @ts-ignore
        selectOption(nodes.indexOf(e.target.closest('li')));
      }
    },
    bindInput: {
      value:
        selectedValues
          ?.map((item) => item.label[0].toUpperCase() + item.label.slice(1))
          .join(', ') || '',
      onKeyDown
    },
    bindOptions: {
      ref: listRef
    },
    selectedIndex
  };
};

export default useSelect;
