import { TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const litroItems: TSelectItem[] = [
  {
    key: 'dealer_code',
    label: 'Дилерский центр',
    editable: false,
    snakeCaseValues: DictTypes.Dealers,
    type: 'select'
  },
  {
    key: 'city_code',
    label: 'Город',
    editable: false,
    // snakeCaseValues: DictTypes.Cities,
    type: 'select'
  },
  // {
  //   key: 'card_name',
  //   label: 'Название карты',
  //   editable: true,
  //   type: 'select'
  // },
  {
    key: 'period_code',
    label: 'Период (в месяцах)',
    editable: true,
    snakeCaseValues: DictTypes.LoanTerms,
    type: 'select'
  },
  {
    key: 'payment_type_code',
    label: 'Тип платежа',
    editable: true,
    type: 'select'
  },

  {
    key: 'car_registration',
    label: 'Регистрационный номер авто',
    editable: true,
    type: 'input'
  },

  {
    key: 'vin',
    label: 'VIN',
    editable: true,
    type: 'input'
  },
  {
    key: 'markModelYear',
    label: 'Марка, модель и год выпуска',
    editable: true,
    snakeCaseValues: DictTypes.Brands,
    type: 'select'
  }
];
