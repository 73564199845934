import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { leasingAdminApi } from '@/integrations/index.api';
import { TLeasingData } from '@/types/leasing';

type TLeasingSlice = {
  isLoading: boolean;
  total: number;
  leasings: TLeasingData[];
};

const initialState: TLeasingSlice = {
  isLoading: false,
  total: 0,
  leasings: []
};

export const getAllLeasing = createAsyncThunk(
  'leasings',
  async ({
    limit,
    offset,
    searchTerm,
    filterByUser
  }: {
    limit?: number;
    offset?: number;
    searchTerm?: string;
    filterByUser?: string;
  }) => {
    const res = await leasingAdminApi.getAdminAllLeasing(
      limit,
      offset,
      searchTerm,
      filterByUser
    );
    return {
      total: res.total,
      data: res.data
    };
  }
);

export const adminLeasingSlice = createSlice({
  name: 'leasing',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeasing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLeasing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.total = action.payload.total;
        state.leasings = action.payload.data;
      })
      .addCase(getAllLeasing.rejected, (state) => {
        state.isLoading = false;
        state.leasings = [];
      });
  }
});

export default adminLeasingSlice.reducer;
