import { formatPhoneNumber } from '@/services/formatter';
import {
  LeasingsClientResponse,
  LeasingServerResponseInterface
} from '../types';

export const leasingsAdapter = (
  data: LeasingServerResponseInterface[]
): LeasingsClientResponse[] => {
  return data.map((item) => ({
    createDate: item.create_date,
    iin: item.iin,
    phoneNumber: formatPhoneNumber(item.contact_phone_number),
    status: {
      name: item.status?.name || '',
      code: item.status?.code || 'leasing_draft',
      uuid: item.status?.uuid || ''
    },
    updateDate: item.update_date,
    fullName: `${item.user?.first_name} ${item.user?.last_name}`
  }));
};
