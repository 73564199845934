import { Modal } from 'antd';
import '@/styles/Modal.scss';
import React from 'react';
// import { footer } from 'antd/es/modal/interface';

export type TCustomModalProps = {
  isModalOpen: boolean;
  handleOk: React.MouseEventHandler;
  handleCancel: (title: string) => void;
  children?: JSX.Element | React.ReactNode;
  footer?: React.ReactNode; //| ModalFooterRender;
  title: string;
  maskClosable?: boolean;
  editClass: string;
};

export const CustomModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  footer,
  title,
  editClass,
  maskClosable
}: TCustomModalProps) => {
  return (
    <Modal
      centered={true}
      className={`CustomModal ${editClass}`}
      title={title}
      open={isModalOpen}
      maskClosable={maskClosable !== undefined ? maskClosable : true}
      onOk={handleOk}
      onCancel={() => handleCancel(title)}
      footer={footer}
    >
      {children}
    </Modal>
  );
};
