import { AxiosInstance } from 'axios';
import { TFlashLog } from '@/types/flashLog.type';

export class LogsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminLogs(
    param: string,
    limit: number,
    page: number
  ): Promise<{ total: number; flash_logs: TFlashLog[] }> {
    const res = await this.api.get(
      `admin/flashlogs/getFlashLogs/${param}?limit=${limit}&page=${page}`
    );
    return res.data;
  }
}
