import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TProduct } from '@/types/product.type';
import { productsApi } from '@/integrations/index.api';

type TProductsSlice = {
  isLoading: boolean;
  products: TProduct[];
  total: number;
};

const initialState: TProductsSlice = {
  isLoading: false,
  products: [],
  total: 0
};

export const getProducts = createAsyncThunk(
  'admin/products/getAll',
  async ({
    limit,
    page,
    param
  }: {
    limit?: number;
    page?: number;
    param?: string;
  }) => {
    try {
      const res = await productsApi.getAdminProducts(limit, page, param);
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createProduct = createAsyncThunk(
  'admin/products/create',
  async (data: TProduct) => {
    const res = await productsApi.createAdminProduct(data);
  }
);

export const updateProduct = createAsyncThunk(
  'admin/products/update',
  async (data: TProduct) => {
    await productsApi.updateAdminProduct(data);
  }
);

export const adminProductsSlice = createSlice({
  name: 'admin/products',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.products;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getProducts.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {} = adminProductsSlice.actions;
