import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TUiControllerSlice = {
  showModalCreateDbTable: boolean;
  showModalCreateSimpleDict: boolean;
  showModalCreateOrEditModel: boolean;
  showModalCreateOrEditDealer: boolean;
  showModalCreateOrEditProduct: boolean;
  showModalCreateOrEditPromoProductModel: boolean;
  showModalCreateOrEditSalePoint: boolean;
  showFlashLogModal: boolean;
  showModalCreateOrEditBank: boolean;
  showUserModal: boolean;
  showClientModal: boolean;
  showPositionModal: boolean;
  showRolesActionModal: boolean;
};

const initialState: TUiControllerSlice = {
  showModalCreateDbTable: false,
  showModalCreateSimpleDict: false,
  showModalCreateOrEditModel: false,
  showModalCreateOrEditDealer: false,
  showModalCreateOrEditProduct: false,
  showModalCreateOrEditPromoProductModel: false,
  showModalCreateOrEditSalePoint: false,
  showFlashLogModal: false,
  showModalCreateOrEditBank: false,
  showUserModal: false,
  showClientModal: false,
  showPositionModal: false,
  showRolesActionModal: false
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetApp: () => initialState,
    setShowModalCreateDbTable: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateDbTable = action.payload;
    },
    setShowModalCreateSimpleDict: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateSimpleDict = action.payload;
    },
    setShowModalCreateOrEditModel: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateOrEditModel = action.payload;
    },
    setShowModalCreateOrEditDealer: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateOrEditDealer = action.payload;
    },
    setShowModalCreateOrEditProduct: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalCreateOrEditProduct = action.payload;
    },
    setShowModalCreateOrEditPromoProductModel: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalCreateOrEditPromoProductModel = action.payload;
    },
    setShowModalCreateOrEditSalePoint: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalCreateOrEditSalePoint = action.payload;
    },
    setShowModalFlashLog: (state, action: PayloadAction<boolean>) => {
      state.showFlashLogModal = action.payload;
    },
    setShowModalCreateOrEditBank: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateOrEditBank = action.payload;
    },
    setShowUserModal: (state, action: PayloadAction<boolean>) => {
      state.showUserModal = action.payload;
    },
    setShowClientModal: (state, action: PayloadAction<boolean>) => {
      state.showClientModal = action.payload;
    },

    setShowPositionModal: (state, action: PayloadAction<boolean>) => {
      state.showPositionModal = action.payload;
    },
    setShowRolesActionModal: (state, action: PayloadAction<boolean>) => {
      state.showRolesActionModal = action.payload;
    }
  }
});
export const {
  resetApp,
  setShowModalCreateDbTable,
  setShowModalCreateSimpleDict,
  setShowModalCreateOrEditModel,
  setShowModalCreateOrEditDealer,
  setShowModalCreateOrEditProduct,
  setShowModalCreateOrEditPromoProductModel,
  setShowModalCreateOrEditSalePoint,
  setShowModalFlashLog,
  setShowModalCreateOrEditBank,
  setShowUserModal,
  setShowClientModal,
  setShowPositionModal,
  setShowRolesActionModal
} = adminSlice.actions;
