import './table.scss';

import { Table, TableProps } from 'antd';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import selectComponentClass from './parts/select-component-class';

interface IProps<T> extends Omit<TableProps<T>, 'onRow'> {
  onRowClick?: (row: T) => void;
  rowLink?: (row: T) => string | undefined;
}

const CustomTable = <T extends object>({
  onRowClick,
  rowLink,
  ...props
}: IProps<T>) => {
  return (
    <div className="table">
      <Table<T>
        {...props}
        columns={props.columns?.map((item) => {
          return {
            ...item,
            render: (value, record, index) => {
              const href = rowLink?.(record);
              const render =
                item && item.render ? (
                  item.render(value, record, index)
                ) : (
                  <>{value}</>
                );

              return href ? (
                <NavLink
                  to={href}
                  onClick={(e) => {
                    if (onRowClick) e.preventDefault();
                  }}
                  data-table-link="true"
                >
                  {render as ReactNode}
                </NavLink>
              ) : (
                render
              );
            }
          };
        })}
        size="small"
        showSorterTooltip={false}
        rowClassName={`table__row ${onRowClick ? 'table__row--clickable' : ''}`}
        pagination={{
          position: ['bottomLeft'],
          showSizeChanger: true,
          ...props.pagination,
          pageSizeOptions: ['10', '20', '50'],
          // @ts-ignore
          selectComponentClass: selectComponentClass(props.pagination)
        }}
        scroll={{
          x: 'max-content'
        }}
        onRow={(r) => {
          return {
            onClick: () => {
              onRowClick?.(r);
            }
          };
        }}
      />
    </div>
  );
};

export default CustomTable;
