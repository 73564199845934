import { InsuranceTableResponse } from '@/types/insuranceMapper';
import { TStatus } from '@/types/request';
import { InsurancesTableInterface } from '@/types/store';

export const insuranceMapperResp = (
  insurances: InsuranceTableResponse
): InsurancesTableInterface => {
  return {
    total: insurances?.total || 0,
    pageCount: insurances?.page_count || 0,
    data: insurances?.data
      ? insurances.data.map((insurance) => {
          return {
            uuid: insurance?.uuid || '',
            clientIin: insurance?.client?.client_iin || '',
            clientPhone: insurance?.client?.phone_number || '',
            lastName: insurance?.client?.last_name || '',
            middleName: insurance?.client?.middle_name || '',
            firstName: insurance?.client?.first_name || '',
            contrancDate: insurance?.contract_date || '',
            dealer: insurance?.dealer?.name || '',
            brand: insurance?.brand?.name || '',
            model: insurance?.model?.name || '',
            year: insurance?.model?.year || '',
            createDate: insurance?.create_date || '',
            status: insurance?.status as TStatus,
            user: {
              first_name: insurance?.user?.first_name || '',
              last_name: insurance?.user?.last_name || '',
              middle_name: insurance?.user?.middle_name || ''
            },
            telNumIin: insurance.telNumIin || '',
            userSearchType: insurance.userSearchType || '',
            insuranceAmount: insurance?.insurance_summ || 0,
            insurancePremium: insurance?.insurance_prem || 0,
            product: {
              code: insurance?.product_type?.code,
              name: insurance?.product_type?.name,
              productCode: insurance?.product_type?.product_code
            }
          };
        })
      : []
  };
};
