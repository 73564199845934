import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { TAuthRequest } from '@/types/api';

import { apiConnector } from '../integrations/api.connector';
import { TAuthStore } from '../types/store';

export const forgotPassword = createAsyncThunk(
  'auth/resetPassword',
  async (email: string): Promise<any> => {
    try {
      const res = await apiConnector.forgotePassword(email);
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password, isRemember }: TAuthRequest): Promise<any> => {
    let ipData;
    try {
      const response = await axios.get(
        'https://api.ipdata.co?api-key=a8d0725d9ce39971566005bdbbf47348d9bfec23a17e49cf080f2ec4'
      );
      ipData = await response.data;
    } catch (e) {
      const err = e as { message: string };
      console.error(err.message);
    }

    try {
      ipData ??= { ip: '', latitude: '', longitude: '' };
      const { ip, latitude, longitude } = ipData;

      return await apiConnector.authJwt(
        email,
        password,
        isRemember,
        ip,
        String(latitude),
        String(longitude)
      );
    } catch (e) {
      const err = e as { message?: string };
      console.error(err?.message);
    }
  }
);

export const tokenValidate = createAsyncThunk('auth/validate', async () => {
  try {
    return await apiConnector.isValid();
  } catch (e: any) {
    throw new Error(e);
  }
});

const initialState: TAuthStore = {
  resetEmail: null,
  isLoading: false,
  loginError: false,
  loginWarning: '',
  isUserAuth: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setResetEmail: (state, action) => {
      state.resetEmail = action.payload;
    },
    resetLoginError: (state) => {
      state.loginError = false;
    },
    toggleLoginError: (state) => {
      state.loginError = !state.loginError;
    },
    setLoginWarning: (state, action: PayloadAction<string>) => {
      state.loginWarning = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isUserAuth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.loginError = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.status) {
          state.isUserAuth = true;
          state.loginError = false;
        } else {
          state.isUserAuth = false;
          state.loginError = true;
        }
      })
      .addCase(tokenValidate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tokenValidate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(tokenValidate.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === false) {
          state.isUserAuth = false;
          clearLocalStorageHelper();
        } else {
          state.isUserAuth = true;
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  setResetEmail,
  setIsAuth,
  resetLoginError,
  toggleLoginError,
  setLoginWarning
} = authSlice.actions;
