import './Navbar.scss';

import { Tooltip } from 'antd';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { AdminNavBar } from '@/components/AdminPanel/NavBar/AdminNavBar';
import {
  pathExpandLogo,
  pathLogo,
  pathQuestionicon,
  pathSettingIcon
} from '@/constants';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTargetedClientToInitial } from '@/store/clients.slice';
import { onSetSelectedBankToInitial } from '@/store/financingStep.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';

export const Navbar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { userInfo, dealer } = useAppSelector((state) => state.userInfo);

  const [isSidebarExpanded, setSidebarExpanded] = useState<boolean>(true);

  const onClick = () => {
    dispatch(onSetSelectedBankToInitial());
    dispatch(setTargetedClientToInitial());
    dispatch(resetReqSuccessIndicator());
  };

  return (
    <aside
      className={`navbar ${
        isSidebarExpanded ? 'navbar--expanded' : 'navbar--collapsed'
      }`}
    >
      <div className="navbar__logo-box">
        <NavLink to="/auto-credit">
          <img src={pathLogo} alt="logo" />
        </NavLink>
        <button onClick={() => setSidebarExpanded(!isSidebarExpanded)}>
          <img src={pathExpandLogo} alt="logo_expand" />
        </button>
      </div>

      <nav className="navbar__navigation-box">
        {location.pathname.includes('/adminPage') ? (
          <AdminNavBar isSidebarExpanded={isSidebarExpanded} />
        ) : (
          userInfo?.frontendBlock
            .slice()
            .filter((item) => item.is_active)
            .sort((a, b) => a.position - b.position)
            .map((element, index) => {
              const isActive =
                (element.code === 'main' && location.pathname === '/') ||
                location.pathname.includes(element.code);
              const href = element.code === 'main' ? '/' : `/${element.code}`;

              if (
                element.code === 'pos-credit' &&
                Number(dealer?.code) !== 1016
              ) {
                return null;
              }
              return (
                <NavLink
                  to={href}
                  style={{ width: isSidebarExpanded ? '220px' : '56px' }}
                  className={`navbar__link ${
                    index === 0 ? 'navbar__link--first' : ''
                  } ${isActive ? 'navbar__link--active' : ''}`}
                  onClick={onClick}
                  key={element.uuid}
                >
                  <Tooltip
                    open={isSidebarExpanded ? false : undefined}
                    title={element?.name}
                    placement="right"
                  >
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={element.icon}
                      alt="setting_icon"
                    />
                  </Tooltip>
                  <span className="navbar__link-text">{element?.name}</span>
                </NavLink>
              );
            })
        )}
      </nav>

      {!location.pathname.includes('/adminPage') ? (
        <div className="navbar__support-setting-box">
          <button className="navbar__link">
            <img src={pathQuestionicon} alt="support_icon" />
            <p>Поддержка</p>
          </button>
          <button className="navbar__link">
            <img src={pathSettingIcon} alt="setting_icon" />
            <p>Настройки</p>
          </button>
        </div>
      ) : null}
    </aside>
  );
};
