import { forwardRef } from 'react';
import { InputMask, Replacement } from '@react-input/mask';

import Input, { IInputProps } from '../input';

export interface MaskedInputInterface extends IInputProps {
  mask?: string;
  replacement?: string | Replacement;
}

const MaskedInput = forwardRef<HTMLInputElement, MaskedInputInterface>(
  ({ mask, replacement = '_', ...props }, forwardedRef) => {
    return (
      <InputMask<typeof Input>
        component={Input}
        ref={forwardedRef}
        mask={mask}
        replacement={replacement}
        {...props}
      />
    );
  }
);

MaskedInput.displayName = 'MaskedInput';

export default MaskedInput;
