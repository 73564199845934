import './styles/App.scss';

import DisableDevtool from 'disable-devtool';
import { jwtDecode } from 'jwt-decode';
import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Litro } from '@/components/App/LitroPnd/Litro';
import { LitroForm } from '@/components/App/LitroPnd/LitroForm/LitroForm';
import BiometricScan from '@/components/BiometricScan/BiometricScan';
import { useAppSelector } from '@/store';

import { Layout } from './components/Layout/Layout';
import CheckAuth from './components/Shared/Route/CheckAuth';
import { ProtectedRoute } from './components/Shared/Route/PrivateRoute';
import SuspenseRoute from './components/Shared/Route/suspense-route';
import { setNavigate } from './services/helpers/navigate-helper';

const LoginPage = lazy(() => import('./components/Auth/login/login'));
const ResetPassword = lazy(() => import('./components/Auth/ResetPassword'));
const NotFound = lazy(() => import('./components/Layout/errors/404'));

const AdminRoutes = lazy(() => import('./components/AdminPanel/admin-routes'));
const MainPage = lazy(() => import('./components/App/MainPage/MainPage'));
const Personal = lazy(() => import('./components/App/Personal/Personal'));

const AutoCred = lazy(() => import('./components/App/AutoCred/AutoCred'));
const NewOrder = lazy(() => import('./components/App/NewOrder/NewOrder'));
const ExistedOrder = lazy(
  () => import('./components/App/NewOrder/ExistedOrder')
);
const Calculator = lazy(() => import('./components/App/Calculator/Calculator'));

const Insurance = lazy(() => import('./components/App/Insurance/Insurance'));
const CreateInsurance = lazy(
  () => import('./components/App/Insurance/insurance-order/create-insurance')
);
const InsuranceOrder = lazy(
  () => import('./components/App/Insurance/insurance-order/insurance-order')
);

const Clients = lazy(() => import('./components/App/Clients/Clients'));
const ClientRequestsDetails = lazy(
  () => import('./components/App/ClientRequestsDetails/ClientRequestsDetails')
);

const Leasing = lazy(() => import('./components/App/Leasing/Leasing'));
const CreateLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/create-leasing')
);
const ExistedLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/existed-leasing')
);

const Reports = lazy(() => import('./components/App/Reports/Reports'));
const Approvement = lazy(
  () => import('./components/App/Approvement/approvement')
);

const PosCreds = lazy(
  () => import('./components/App/PosCred/PosCreds/pos-creds')
);
const PosCredApplication = lazy(
  () =>
    import('./components/App/PosCred/PosCredApclication/pos-cred-aplication')
);

export const App = () => {
  const navigate = useNavigate();

  const { isUserAuth } = useAppSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Set global navigate to access from everywhere
  setNavigate(navigate);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const user: { actions: number[] } = jwtDecode(token);
      if (user.actions.includes(0)) {
        setIsAdmin(true);
      } else {
        DisableDevtool({
          disableMenu: false,
          ignore: ['/login', /\/reset_password\/.+/]
        });
      }
    }
  }, [isUserAuth]);

  return (
    <>
      <BiometricScan />
      <Routes>
        <Route element={<CheckAuth />}>
          <Route path="/" element={<Layout />}>
            <Route path="/*" element={<NotFound />} />

            <Route
              element={
                <ProtectedRoute
                  redirectPath={'/login'}
                  isAllowed={isUserAuth}
                />
              }
            >
              <Route path="/" element={<MainPage />} />
              <Route path="/auto-credit" element={<AutoCred />} />
              <Route path="/auto-credit/create" element={<NewOrder />} />
              <Route path="/auto-credit/:uuid" element={<ExistedOrder />} />
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/insurances" element={<Insurance />} />
              <Route path="/insurances/create" element={<CreateInsurance />} />
              <Route path="/insurances/:uuid" element={<InsuranceOrder />} />
              <Route path="/personal" element={<Personal />} />
              <Route path="/settlement" element={<></>} />
              <Route path="/leasing" element={<Leasing />} />
              <Route path="/leasing/create" element={<CreateLeasing />} />
              <Route path="/leasing/:uuid" element={<ExistedLeasing />} />
              <Route path="/clients" element={<Clients />} />
              <Route
                path="/clients/:uuid"
                element={<ClientRequestsDetails />}
              />
              <Route path="/additional-product-pnd" element={<Litro />} />
              <Route path="/pnd-form" element={<LitroForm />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/editor" element={<></>} />
              <Route path="/pos-credit" element={<PosCreds />} />
              <Route path="/pos-credit/:id" element={<PosCredApplication />} />
              <Route
                path="/pos-credit/create/:id"
                element={<PosCredApplication />}
              />
              {isAdmin && (
                <Route path="/adminPage/*" element={<AdminRoutes />} />
              )}
            </Route>
          </Route>

          <Route element={<SuspenseRoute />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<SuspenseRoute />}>
            <Route
              path={`/reset_password/:token`}
              element={<ResetPassword />}
            />
          </Route>
        </Route>

        <Route element={<SuspenseRoute />}>
          <Route path="/approvements" element={<Approvement />} />
        </Route>
      </Routes>
    </>
  );
};
