import './Layout.scss';

import { Spin } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import SelectDealerModal from '@/components/App/SelectDealer/SelectDealerModal';
import { Header } from '@/components/Layout/Header/Header';
import { Navbar } from '@/components/Layout/Navbar/Navbar';
import { useAppDispatch, useAppSelector } from '@/store';
import { setDealer } from '@/store/userInfo.slice';

import ErrorTemplate from './errors/error-template';

export const Layout = () => {
  const dealers = useAppSelector((state) => state.dict.activeDealersOption);
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const { dealer } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  const [showSelectDealerModal, setSelectDealerModal] = useState<boolean>(true);

  useEffect(() => {
    const dealerCode = localStorage.getItem('dealer');

    if (dealerCode) {
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: string;
          }
        | undefined =
        dealers &&
        dealers.find((_dealer) => _dealer.code.toString() == dealerCode);
      setSelectDealerModal(false);

      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
  }, [dealers]);

  useEffect(() => {
    if (isUserAuth && !dealer?.value) {
      setSelectDealerModal(true);
    }
  }, [isUserAuth, dealer]);

  const showSelectDealerModalToggle = () => {
    setSelectDealerModal(!showSelectDealerModal);
  };

  return (
    <div className="Layout">
      {isUserAuth && showSelectDealerModal && (
        <SelectDealerModal show={showSelectDealerModalToggle} />
      )}
      <Navbar />
      <div className="wrapper">
        <Header />
        <main>
          <ErrorBoundary fallback={<ErrorTemplate type="oops" />}>
            <Suspense fallback={<Spin fullscreen />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </main>
      </div>
    </div>
  );
};
